import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tencate-grass"
    }}>{`TenCate Grass`}</h1>
    <h2 {...{
      "id": "project-outline"
    }}>{`Project outline`}</h2>
    <p>{`TenCate Grass consists of a group of international manufacturers, suppliers and installers of artificial grass and athletic tracks on a joint mission to create the best and most sustainable synthetic surfaces for the sports and leisure industry. HybrIT helped TenCate Grass to setup a `}<strong parentName="p">{`event driven integration solution`}</strong>{` to establish communications and syncronizations between multiple systems. `}</p>
    <h2 {...{
      "id": "architecture"
    }}>{`Architecture`}</h2>
    <p><img alt="Logical architecture" src={require("./images/tencategrass-logical-architecture.png")} /></p>
    <h2 {...{
      "id": "coding--techniques"
    }}>{`Coding & techniques`}</h2>
    <ul>
      <li parentName="ul">{`Integration in `}<strong parentName="li">{`Mulesoft`}</strong></li>
    </ul>
    <h2 {...{
      "id": "environments"
    }}>{`Environments`}</h2>
    <ul>
      <li parentName="ul">{`Production - `}<a parentName="li" {...{
          "href": "https://tcg-gateway-api.us-e2.cloudhub.io"
        }}>{`https://tcg-gateway-api.us-e2.cloudhub.io`}</a></li>
      <li parentName="ul">{`Staging - `}<a parentName="li" {...{
          "href": "https://tcg-gateway-api-staging.us-e2.cloudhub.io"
        }}>{`https://tcg-gateway-api-staging.us-e2.cloudhub.io`}</a></li>
    </ul>
    <h2 {...{
      "id": "repositories"
    }}>{`Repositories`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Repository`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gateway Api`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Back-end/API for external access`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/tcg-gateway-api"
            }}>{`https://github.com/HybrIT-dev/tcg-gateway-api`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Events Service`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Back-end/API for events`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/tcg-events-api"
            }}>{`https://github.com/HybrIT-dev/tcg-events-api`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Dispatcher Service`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Back-end/API for dispatching events dynamically`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/tcg-dispatcher"
            }}>{`https://github.com/HybrIT-dev/tcg-dispatcher`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Alis Service`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Back-end/API for ALIS QI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/tcg-alis-api"
            }}>{`https://github.com/HybrIT-dev/tcg-alis-api`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Navision Service`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Back-end/API for Navision`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/tcg-nav-api"
            }}>{`https://github.com/HybrIT-dev/tcg-nav-api`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Salesforce Service`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Back-end/API for Salesforce`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/tcg-sf-api"
            }}>{`https://github.com/HybrIT-dev/tcg-sf-api`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Trekbank Service`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Back-end/API for Trekbank`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/tcg-trekbank-api"
            }}>{`https://github.com/HybrIT-dev/tcg-trekbank-api`}</a></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "getting-started-with-development"
    }}>{`Getting started with development`}</h2>
    <h3 {...{
      "id": "required-tools"
    }}>{`required tools`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.mulesoft.com/lp/dl/studio"
        }}>{`Anypoint Studio`}</a></li>
      <li parentName="ul">{`GlobalProtect VPN`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      